import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { ModalFormWrapper } from '@/components/form/modal';
import currencyFormat from '@/helpers/currencyFormat';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import type { Item, QueryItemsReadArgs } from '@/types/schema';
import { Divider, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { round, truncate } from 'lodash-es';
import React, { Fragment } from 'react';
import ItemForm from '../dashboard/management/items/form';
import { ItemsSelectRead } from './selectsGQL';

export default function ItemSelect( {
	isPurchase,
	...props
}: Omit<FormGraphQLAutoCompleteProps<Item, QueryItemsReadArgs>, 'query' | 'queryKey'> & { isPurchase?: boolean } ) {
	const creatable = usePermissions( permissions.items.write );
	const { showModal } = useModal();
	const { staff } = useUserInfo();
	const [ , { value = [] }, { setValue } ] = useField( props.name );
	
	const cashDiscount = !isPurchase && !staff.company?.metadata?.hasCloverCashDiscount
		? round( staff?.company.metadata?.cashDiscount || 0, 2 ) / 100 : 0;
	
	return (
		<FormGraphQLAutocomplete<Item>
			label='Item'
			queryKey={[ 'itemsRead' ]}
			query={ItemsSelectRead}
			getOptionLabel={( { name, description, uoms }: Item ) => [
				name,
				description,
				uoms?.length ? ` - ${uoms.filter( ( uom ) => !uom.removed ).map( ( uom ) => `(${currencyFormat( isPurchase
					? uom.cost + uom.cost * cashDiscount
					: uom.price + uom.price * cashDiscount )}) ${uom.code
					? `(${uom.code})`
					: ''} ${uom.sku ? `(${uom.sku})` : ''}` )
					.join( ' ' )}`
					: '' ].join( ' ' ).trim()}
			renderOption={( props, option ) =>
				(
					<Fragment key={option.id ?? ''}>
						<MenuItem {...props}>
							<Stack sx={{ width: '100%' }}>
								<Stack direction='row' justifyContent='space-between'>
									<Typography fontWeight={500}>
										{option.name}
									</Typography>
									<Typography fontWeight={200}>
										{option.categories?.[ 0 ]?.name || ''}
									</Typography>
								</Stack>
								{option.uoms?.filter( ( uom ) => !uom.removed )?.map( ( uom ) => (
									<Fragment key={uom.id}>
										<Stack spacing={1} direction='row'>
											<Typography>
												{uom.name}
											</Typography>
											<Typography mx={1}>
												{currencyFormat( isPurchase
													? uom.cost + uom.cost * cashDiscount
													: uom.price + uom.price * cashDiscount ) || '$0.00'}
											</Typography>
										</Stack>
										<Grid
											container
											spacing={1}
											sx={{ '.MuiTypography-root': { fontSize: { xs: 11, sm: 12 } } }}>
											<Grid item xs={5}>
												{uom.code && (
													<Typography color='text.secondary'>
														Code: {uom.code}
													</Typography>
												)}
											</Grid>
											<Grid item xs={4}>
												{uom.sku && (
													<Typography sx={{ textAlign: 'center' }} color='text.secondary'>
														sku: {uom.sku}
													</Typography>
												)}
											</Grid>
											<Grid item xs={3}>
												{uom.quantity ? (
													<Typography sx={{ textAlign: 'right' }}>
														{uom.quantity} available
													</Typography>
												) : null}
											</Grid>
										</Grid>
										<Stack
											direction='row'
											sx={{
												'justifyContent'     : 'space-between',
												'alignItems'         : 'end',
												'.MuiTypography-root': { fontSize: { xs: 11, sm: 12 } },
												'textAlign'          : 'right',
												'color'              : 'text.secondary',
											}}>
											{option?.locations?.[ 0 ] && (
												<Typography>
													{option.locations[ 0 ].name || option.locations[ 0 ]?.address?.line1}
												</Typography>
											)}
											<Stack direction='row'>
												{Boolean( uom.min ) && (
													<Typography>
														min {uom.min}
													</Typography>
												)}
												{Boolean( uom.min ) && Boolean( uom.max ) && (
													<Typography sx={{ px: 0.5 }}>
														-
													</Typography>
												)}
												{Boolean( uom.max ) && (
													<Typography>
														max {uom.max}
													</Typography>
												)}
											</Stack>
										</Stack>
										<Typography>
											{truncate( option.description, { length: 80, omission: '...' } )}
										</Typography>
									</Fragment>
								) )}
							</Stack>
						</MenuItem>
						<Divider sx={{ my: 1 }}/>
					</Fragment>
				
				)}
			onAdd={creatable ? () => showModal( ItemForm, { fullPageModal: true, maxWidth: 'md' }, {
				Wrapper : ModalFormWrapper,
				onSubmit: ( item ) => setValue( props.multiple ? [ ...value, item ] : item ),
			} ) : undefined}
			{...props}
			textFieldProps={{ label: props.label || 'Item', ...props.textFieldProps }}
		/>
	);
}
