import { ActionProps } from '@/components/actions';
import { useGraphQL } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { ClientRead } from '@/data/management/client.graphql';
import { LineItemRead, LineItemWrite } from '@/data/management/lineItem.graphql';
import { PricesRead } from '@/data/management/price.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import uploadFile from '@/data/uploadFile';
import { QueryClientReadArgs } from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import idPick from '@/helpers/idPick';
import useAccountType from '@/helpers/useAccountType';
import LineItemStatus from '@/pages/dashboard/commerce/form/lineItemForm/lineItemStatus';
import { createItemFromLineItem } from '@/pages/dashboard/commerce/purchases/actions/receivingActions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import type { FilterOptions, LineItem, Order, Price, Purchase, QueryPricesReadArgs } from '@/types/schema';
import { MutationLineItemWriteArgs } from '@/types/schema';
import type { ButtonProps } from '@mui/material';
import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { atom } from 'jotai/index';
import { isEmpty, isNumber, orderBy, pick, round, set } from 'lodash-es';
import { useRouter } from 'next/router';
import React, { ComponentType, Fragment, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { lineItemTotalCalculation } from './calculation';
import LineItemFormDetails from './details';
import LineItemFees from './fees';
import MarginMarkup from './markup';
import ModifierGroupsSelect from './modifierGroupsSelect';
import LineItemTaxes from './taxes';
import LineItemFormUom from './uom';

const validationSchema = yup.object().shape( {
	name: yup
		.string()
		.required( 'Enter item name' )
		.max( 200, 'Item name is too long' ),
	unit: yup
		.string()
		.required( 'Enter UOM name' )
		.max( 64, 'Uom is too long' ),
	price: yup
		.number()
		.required( 'Enter price' )
		.lessThan( 1000000000, 'Price exceeded' ),
} );

export function useCloverTaxes( gatewayId: string,
	enabled: boolean = true,
	variables?: FilterOptions ): [ Price[], boolean ] {
	const { staff } = useUserInfo();
	const isCloverAccount = useAccountType( 'CLOVER' );
	
	// get items clover taxes
	const { data, isFetching } = useGraphQL<QueryPricesReadArgs>( {
		query    : PricesRead,
		queryKey : [ 'pricesRead' ],
		variables: {
			options: variables || {
				limit : 60,
				filter: {
					name      : { $nin: [ 'Tax (auto)', 'NO_TAX_APPLIED', 'NO_TAXAPPLIED' ] },
					company   : staff?.company?.id,
					externalId: { $ne: null },
					isPercent : true,
					payment   : null,
					gateway   : gatewayId,
				},
			},
		},
	}, { enabled: enabled || isCloverAccount && Boolean( gatewayId ), keepPreviousData: true } );
	const [ firstFetching, setFirstFetching ] = useState( true );
	
	useEffect( () => {
		if ( !isFetching ) setFirstFetching( false );
	}, [ isFetching ] );
	
	return [ data?.pricesRead?.items ?? [], firstFetching ];
}

export const cashDiscountAtom = atom<number>( 0 );

export default function LineItemForm( {
	id,
	onSubmit,
	Wrapper = Fragment,
	isPurchase,
	clientId,
	order,
	purchase,
}: {
	id?: string,
	onSubmit?: ( lineItem: LineItem, deleteLineItem?: boolean ) => void,
	lineItems?: LineItem[],
	isPurchase?: boolean,
	clientId?: string,
	order?: Order,
	purchase?: Purchase,
	Wrapper: ComponentType<{
		name: string,
		secondaryTitle: string,
		children: ReactNode,
		saveButtonText: string | ReactNode,
		actionItems: ActionProps[],
		topActionItems: ActionProps[],
		saveButtonProps?: ButtonProps
	}>
} ) {
	const router = useRouter();
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const queryClient = useQueryClient();
	const { closeModal } = useModalControls();
	// get clover taxes
	const [ itemsCloverTaxes, isFetching ] = useCloverTaxes( order?.companyLocation?.gateway?.id || '' );
	const [ cashDiscount, setCashDiscount ] = useAtom( cashDiscountAtom );
	
	const { data } = useGraphQL<QueryClientReadArgs>( {
		query    : ClientRead,
		queryKey : 'client',
		variables: { id: clientId },
	}, { enabled: Boolean( clientId ) } );
	
	useEffect( () => {
		const discount = !isPurchase && !staff?.company?.metadata?.hasCloverCashDiscount
			? round( staff?.company.metadata?.cashDiscount || 0, 2 ) / 100
			: 0;
		setCashDiscount( discount );
	}, [ isPurchase, staff?.company?.metadata ] );
	
	const customSku = ( order || purchase )?.company?.metadata?.customSKU || '';
	
	return (
		<FormGraphqlProvider<LineItem & { withoutCashDiscount?: boolean }>
			id={id}
			queryKey='lineItem'
			query={LineItemRead}
			initialValues={() => ( {
				orderTax: !data?.clientRead?.metadata?.exemptFromTax && !id,
				unit    : 'Unit',
				quantity: 1,
				price   : 0,
				prices  : itemsCloverTaxes && !isEmpty( itemsCloverTaxes ) && !isPurchase
					? itemsCloverTaxes.map( ( tax ) => ( {
						...tax,
						externalId: null as any,
						metadata  : {
							...tax.metadata,
							externalId: tax.externalId,
							useTax    : false,
						},
						id: uuidv4(),
					} ) )
					: [],
				withoutCashDiscount: false,
			} )}
			validationSchema={validationSchema}
			onSubmit={async ( values ) => {
				values.price = values.price || 0;
				values.cost = values.cost || 0;
				values.prices = values.prices?.filter( ( price ) => price.name !== 'Tax (auto)' );
				const customSKU = !values.id && !values.item && !values.sku && customSku;
				
				if ( isPurchase && purchase ) {
					const largestSequence = orderBy( purchase.lineItems, [ 'sequence' ], [ 'desc' ] )?.[ 0 ]?.sequence;
					
					if ( !values.item?.id && purchase ) {
						const {
							uom,
							newItem,
						} = await createItemFromLineItem( purchase, values, purchase?.companyLocation, staff! );
						values.item = newItem;
						values.uom = uom;
						values.sku = uom?.sku || customSku;
						await queryClient.invalidateQueries( [ 'user' ] );
					}
					
					const { lineItemWrite } = await mutateGraphQL<MutationLineItemWriteArgs>( {
						mutation : LineItemWrite,
						variables: {
							id       : values.id,
							customSKU: Boolean( customSKU ) && !values.sku,
							input    : {
								purchase: purchase?.id || router.query.id as string,
								...pick( values, [
									'name',
									'description',
									'quantity',
									'code',
									'externalId',
									'metadata',
									'markup',
									'cost',
									'sku',
									'status',
									'orderTax',
									'noCommission',
								] ),
								image: values?.image
									? await uploadFile( values?.image as File | Blob | string || '' )
									: null,
								price   : isNumber( values.price ) ? values.price : 0,
								uom     : values.uom?.id,
								unit    : values.uom?.name,
								item    : values.item?.id,
								category: values.category?.id || null,
								tax     : values.tax,
								sequence: isNumber( values.sequence ) ? values.sequence : isNumber( largestSequence )
									? largestSequence + 1
									: 5,
							},
						},
					} );
					
					onSubmit?.( lineItemWrite );
				} else {
					
					const hasExternalTax = values.prices?.find( ( price ) => price.metadata?.useTax && price.value > 0 );
					
					const noItemAndWithCashDiscount = !values.id && !values.item && cashDiscount > 0 && !values.withoutCashDiscount;
					const cdValue = cashDiscount ? round( values.price ? values.price * cashDiscount : 0, 2 ) : 0;
					
					const price = isNumber( values.price ) ? noItemAndWithCashDiscount
						? values.price + cdValue
						: values.price : 0;
					
					if ( !values.item && cashDiscount ) {
						values.originalPrice = price - cdValue;
						values.cashDiscount = cdValue;
					}
					
					const { lineItemWrite } = await mutateGraphQL<MutationLineItemWriteArgs>( {
						mutation : LineItemWrite,
						variables: {
							id       : values.id,
							customSKU: Boolean( customSKU ),
							input    : {
								order: order?.id || router.query.id as string,
								...pick( values, [
									'name',
									'description',
									'unit',
									'quantity',
									'code',
									'externalId',
									'metadata',
									'markup',
									'cost',
									'orderTax',
									'status',
									'noCommission',
								] ),
								image: values?.image
									? await uploadFile( values?.image as File | Blob | string || '' )
									: null,
								sku          : customSKU || values.sku,
								price,
								originalPrice: values?.originalPrice,
								cashDiscount : values?.cashDiscount,
								tax          : data?.clientRead?.metadata?.exemptFromTax ? 0 : hasExternalTax ? 0 : values.tax,
								sequence     : values.sequence || 5,
								prices       : values.prices?.map( ( price ) => ( {
									...idPick( price, [
										'name',
										'isPercent',
										'value',
										'quantity',
									] ),
									externalId: null,
									metadata  : price.metadata || {},
								} ) ),
								uom           : values.uom?.id || undefined,
								item          : values.item?.id ?? undefined,
								category      : values.category?.id || null,
								modifierGroups: values.modifierGroups?.map( ( { id } ) => id ),
							},
						},
					} );
					set( lineItemWrite, 'price', isPurchase ? values.price : noItemAndWithCashDiscount
						? values.price + values.price * cashDiscount
						: values.price );
					set( lineItemWrite, 'id', lineItemWrite?.id || values?.id || null );
					set( lineItemWrite, 'tax', data?.clientRead?.metadata?.exemptFromTax ? 0 : values.tax === null
						? values.tax
						: hasExternalTax
							? 0
							: values.tax );
					// update stock
					if ( values.id && ( values?.soldQuantity || 0 ) > 0 ) {
						axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/user/inventory/autoStockLineItem`, {
							id    : values.id,
							action: 'Update Stock after updating Item',
						} ).catch( () => {} );
					}
					
					onSubmit?.( lineItemWrite );
				}
			}}>
			{( formik ) => {
				const {
					total,
					noItemWithCashDiscount,
				} = lineItemTotalCalculation( formik.values, cashDiscount, isPurchase );
				
				return (
					<Wrapper
						name='Line Item'
						secondaryTitle={t( 'commerce:changes-not-affect-original-item' )}
						topActionItems={[
							...formik.values.id ? [
								{
									name       : 'Delete',
									buttonProps: { variant: 'outlined' },
									onClick    : () => {
										onSubmit?.( formik.values, true );
										closeModal();
									},
								},
							] : [],
							...!formik.values.id ? [
								{
									name       : 'Clear',
									buttonProps: { variant: 'outlined', color: 'warning' },
									onClick    : () => {
										formik.resetForm();
									},
								},
							] : [],
						] as ActionProps[]}
						actionItems={[ noItemWithCashDiscount ? {
							name       : t( 'commerce:add-without-cash-discount' ),
							buttonProps: { variant: 'contained' },
							onClick    : async () => {
								await formik.setFieldValue( 'withoutCashDiscount', true );
								await formik.submitForm();
								closeModal();
							},
						} : null ].filter( Boolean ) as ActionProps[]}
						saveButtonText={`${t( 'common:add' )} ${currencyFormat( noItemWithCashDiscount
							? total + total * cashDiscount
							: total )}`}>
						<Stack>
							<LineItemFormDetails
								isPurchase={isPurchase}
								order={order}
								purchase={purchase}
								client={data?.clientRead}
								itemsCloverTaxes={itemsCloverTaxes}
							/>
							<LineItemTaxes
								client={data?.clientRead}
								order={order}
								itemsCloverTaxes={itemsCloverTaxes}
								isFetching={isFetching}
							/>
							<MarginMarkup isPurchase={isPurchase}/>
							<LineItemFormUom isPurchase={isPurchase} client={data?.clientRead}/>
							<ModifierGroupsSelect/>
							<LineItemFees isPurchase={isPurchase}/>
							<LineItemStatus/>
						</Stack>
					</Wrapper>
				);
			}}
		</FormGraphqlProvider>
	);
}
