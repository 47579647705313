import FormImage from '@/components/form/fields/image';
import FormTextField from '@/components/form/fields/textField';
import TextFieldInputLabel from '@/components/form/inputLabel';
import PageSection from '@/components/page/section';
import { queryGraphQL } from '@/data/apollo';
import { Uom, UomBase } from '@/generated/graphql';
import { cashDiscountAtom } from '@/pages/dashboard/commerce/form/lineItemForm/index';
import { setPriceAndOriginalPrice } from '@/pages/dashboard/commerce/form/lineItemForm/uom';
import { getMSRPPriceForClient } from '@/pages/dashboard/commerce/form/lineItemForm/utils';
import CategorySelect from '@/pages/formSelects/categorySelect';
import ItemSelect from '@/pages/formSelects/itemSelect';
import { useLocations } from '@/pages/formSelects/locationSelect';
import { ItemSelectRead } from '@/pages/formSelects/selectsGQL';
import {
	Category,
	Client,
	Item,
	LineItem,
	Location,
	Order,
	Price,
	PriceBase,
	Purchase,
	QueryItemReadArgs,
} from '@/types/schema';
import {
	Box,
	Collapse,
	FormHelperText,
	Grid,
	InputAdornment,
	MenuItem,
	Select,
	Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useAtom } from 'jotai';
import { differenceWith, isEmpty, omit, toLower, uniqBy } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useAsyncEffect } from 'rooks';
import { v4 as uuidv4 } from 'uuid';

export const getLineItemMarkUpAndDiscount = ( item: Item, uom: UomBase, client?: Client ) => {
	const clientCategoryWithDiscount = client?.categories?.filter( ( category ) => category.type === 'CLIENT' && ( category.clientDiscount || 0 ) > 0 )
		.sort( ( a, b ) => ( b.clientDiscount || 0 ) - ( a.clientDiscount || 0 ) );
	
	const clientDiscount = clientCategoryWithDiscount?.[ 0 ]?.clientDiscount || 0;
	
	const clientCategoryWithMarkup = !clientDiscount && client?.categories?.filter( ( category ) => category.type === 'CLIENT' && ( category.markup || 0 ) > 0 )
		.sort( ( a, b ) => ( b.markup || 0 ) - ( a.markup || 0 ) );
	
	const markUpTotal = clientDiscount
		? null
		: clientCategoryWithMarkup?.[ 0 ]?.markup || uom?.markup || item.markupPrices?.reduce( ( sum,
			markup ) => sum + markup.value, 0 ) || 0;
	
	return { clientDiscount, markUpTotal };
};

export function LineItemLocationSelect( {
	lineItem,
	locations,
	locationId,
	setLocationId,
	order,
	companyLocation,
}: {
	lineItem?: LineItem | undefined,
	locations: Location[],
	locationId: string,
	setLocationId: ( locationId: string ) => void,
	companyLocation: Location | undefined,
	order?: Order | null
} ) {
	const { t } = useTranslation();
	
	const { enqueueSnackbar } = useSnackbar();
	
	const alertOnLocationChange = ( selectedLocationId: string ) => {
		if ( companyLocation?.gateway?.external === 'CLOVER' && selectedLocationId !== companyLocation?.id ) {
			enqueueSnackbar( `Please make sure to select items from the specific location for which you're creating the ${order
				? toLower( order.type )
				: 'purchase'}`, { variant: 'info' } );
		}
		setLocationId( selectedLocationId === 'all' ? '' : selectedLocationId );
	};
	
	if ( lineItem?.id || isEmpty( locations ) ) return null;
	
	return (
		<Box>
			{!lineItem?.item
				&& <TextFieldInputLabel label={t( 'common:locations' )} tooltip='Filter items by location'/>}
			{!lineItem?.item && (
				<Select
					sx={{ width: 380 }}
					value={locationId || 'all'}
					onChange={( e ) => alertOnLocationChange( e.target.value as string )}>
					<MenuItem selected value='all'>
						{t( 'commerce:all-locations' )}
					</MenuItem>
					{locations.map( ( location, index ) => (
						<MenuItem
							key={index}
							selected
							value={location.id}>
							{`${location.address.line1}`}
						</MenuItem>
					) )}
				</Select>
			)}
			<Collapse in={Boolean( locationId )}>
				<FormHelperText sx={{ color: 'warning.main' }}>Show this location's items only</FormHelperText>
			</Collapse>
		</Box>
	);
}

export default function LineItemFormDetails( { isPurchase, order, purchase, client, itemsCloverTaxes }: {
	isPurchase?: boolean,
	order?: Order,
	purchase?: Purchase,
	client?: Client,
	itemsCloverTaxes?: Price[]
} ) {
	const formik = useFormikContext<LineItem & { categories: Category[] }>();
	const item = formik.values.item;
	const ref = useRef<HTMLDivElement>( null );
	const { t } = useTranslation();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const companyLocation = ( order || purchase )?.companyLocation;
	
	const [ locations ] = useLocations( Boolean( formik.values.id ) );
	const [ locationId, setLocationId ] = useState( companyLocation?.id || '' );
	const [ cashDiscount ] = useAtom( cashDiscountAtom );
	
	useEffect( () => {
		if ( formik.values.id && !isPurchase ) {
			const externalTaxes = formik.values.prices?.filter( ( price ) => price.metadata?.externalTax && price.metadata?.useTax );
			if ( !isEmpty( externalTaxes ) ) {
				formik.setFieldValue( 'tax', 0 );
				formik.setFieldValue( 'orderTax', false );
			}
		}
	}, [] );
	
	const gateway = order?.gateway?.external === 'CLOVER' || order?.companyLocation?.gateway?.external === 'CLOVER';
	
	useUpdateEffect( () => {
		if ( !item ) return;
		
		formik.setFieldValue( 'name', item.name );
		formik.setFieldValue( 'image', item.image );
		formik.setFieldValue( 'description', item.description );
		formik.setFieldValue( 'metadata', {} );
		formik.setFieldValue( 'noCommission', item.noCommission );
		
		if ( item.uoms?.length ) {
			const uom = item.uoms.find( ( uom ) => uom.selected || !uom.removed );
			
			const { clientDiscount, markUpTotal } = getLineItemMarkUpAndDiscount( item, uom!, client );
			
			const price = !clientDiscount && !markUpTotal
				? uom?.price
				: clientDiscount > 0
					? ( uom?.price || 0 ) - ( uom?.price || 0 ) * clientDiscount / 100
					: ( uom?.cost || 0 ) * markUpTotal / 100 + ( uom?.cost || 0 );
			
			formik.setFieldValue( 'uom', {
				id      : uom?.id,
				name    : uom?.name,
				price,
				cost    : uom?.cost,
				sku     : uom?.sku,
				quantity: uom?.quantity,
				code    : uom?.code,
				markup  : markUpTotal,
				
			} );
			formik.setFieldValue( 'markup', markUpTotal );
			
			if ( uom?.image ) {
				formik.setFieldValue( 'image', item.image );
			}
		}
		if ( !item.taxable ) {
			formik.setFieldValue( 'tax', 0 );
			formik.setFieldValue( 'orderTax', false );
		}
		if ( !isPurchase ) {
			formik.setFieldValue( 'orderTax', item.taxable );
			formik.setFieldValue( 'tax', item.taxable ? null : 0 );
		}
		
		if ( !isEmpty( item.categories ) ) {
			formik.setFieldValue( 'category', item.categories?.[ 0 ] );
		}
		
		const itemFeesAndDiscount = !isPurchase && item.metadata?.prices?.map( ( price ) => ( { ...omit( price, [ 'id' ] ) } ) );
		
		if ( !isEmpty( itemFeesAndDiscount ) ) {
			formik.setFieldValue( 'prices', itemFeesAndDiscount );
		}
		
		if ( !formik.values.id && !isPurchase ) {
			if ( !isEmpty( item?.modifierGroups ) ) {
				formik.setFieldValue( 'modifierGroups', item.modifierGroups );
			}
			
			const itemTaxes = uniqBy( item?.taxes?.filter( ( tax ) => tax.externalId && tax.name !== 'Tax (auto)' ), 'externalId' );
			
			const cloverTaxes = differenceWith( itemsCloverTaxes, itemTaxes, ( a: Price,
				b: PriceBase ) => a?.externalId === b?.externalId );
			
			// resave prices when item changes, copy over items fees and taxes to line item prices
			formik.setFieldValue( 'prices',
				[ ...!isEmpty( itemFeesAndDiscount )
					? itemFeesAndDiscount.map( ( price ) => ( { ...omit( price, [ 'id' ] ) } ) )
					: [],
				...!isEmpty( itemTaxes ) ? itemTaxes.map( ( tax ) => ( {
					...tax,
					externalId: null,
					metadata  : {
						...tax.metadata,
						externalId: tax.externalId,
						useTax    : !client?.metadata?.exemptFromTax, // select this tax by default if client is not exempt from tax
					},
					id: uuidv4(),
				} ) ) : [],
				...!isEmpty( cloverTaxes ) ? cloverTaxes.map( ( tax ) => ( {
					...tax,
					externalId: null,
					metadata  : {
						...tax.metadata,
						externalId: tax.externalId,
						useTax    : false,
					},
					id: uuidv4(),
				} ) ) : [],
				],
			);
			if ( !isEmpty( itemTaxes ) ) {
				formik.setFieldValue( 'orderTax', false );
				formik.setFieldValue( 'tax', 0 );
			}
			
		}
		
	}, [ item ] );
	
	useAsyncEffect( async () => {
		if ( item && client?.id ) {
			// get client MSRP price
			const price = await getMSRPPriceForClient( client as Client, item );
			if ( price ) setPriceAndOriginalPrice( formik, price, cashDiscount );
		}
	}, [ item ] );
	
	const descriptionLength = formik.values.description?.length || 0;
	
	return (
		<Fragment>
			<LineItemLocationSelect
				lineItem={formik.values}
				locationId={locationId}
				setLocationId={setLocationId}
				locations={locations}
				companyLocation={companyLocation}
				order={order}
			/>
			<PageSection
				primary={t( 'common:details' )}
				primaryTypographyProps={{ variant: 'h5' }}>
				<Grid container spacing={1}>
					<Grid ref={ref} item xs={12}>
						<FormImage
							name='image'
							buttonName={t( 'common:add-item-image' )}
							cropperProps={{
								aspectRatio: 0,
							}}
							sx={{
								width         : ref.current?.clientWidth || 1,
								height        : ( ref.current?.clientWidth || 0 ) / 16 * 9,
								display       : 'flex',
								backgroundSize: 'contain',
								alignItems    : 'center',
								justifyContent: 'center',
								img           : { objectFit: 'contain' },
							}}
						/>
					</Grid>
					{!formik.values.item && (
						<Grid item xs={12}>
							<CategorySelect
								multiple
								fullWidth
								type='ITEM'
								label='Categories'
								name='categories'
								onAdd={undefined}
							/>
							<Collapse in={!isEmpty( formik.values.categories )}>
								<FormHelperText sx={{ color: 'warning.main' }}>Search item in these categories
									only
								</FormHelperText>
							</Collapse>
						</Grid>
					)}
					<Grid container item xs={12} spacing={1}>
						<Grid item xs={12}>
							{!formik.values.id && (
								<ItemSelect
									blurOnSelect
									isPurchase={isPurchase}
									textFieldProps={{ placeholder: t( 'common:search-item-placeholder' ) }}
									variables={{
										options: {
											limit : 50,
											filter: {
												isHidden  : null,
												categories: !isEmpty( formik.values.categories )
													? formik.values.categories?.map( ( category ) => category.id )
													: undefined,
												$and: locations?.length > 1 && locationId ? [
													{
														$or: [
															{ locations: locationId },
															{ locations: null },
														],
													},
												] : undefined,
												uoms: { removed: null },
												name: { $ne: null },
											},
										},
									}}
									sx={{
										'.MuiOutlinedInput-notchedOutline': {
											borderColor: 'divider',
											borderStyle: 'dashed',
											borderWidth: 2,
										},
									}}
									name='item'
									label={isMobile ? 'Select an Item' : t( 'common:search-item-label' )}
									onChange={async ( e, value: Item ) => {
										if ( value?.id ) {
											const { itemRead } = await queryGraphQL<QueryItemReadArgs>( {
												query    : ItemSelectRead,
												variables: { id: value.id },
											} );
											await formik.setFieldValue( 'item', itemRead );
										}
										if ( !value ) {
											formik.resetForm();
										}
									}}
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								fullWidth
								disabled={Boolean( item?.id ) && isPurchase}
								name='name'
								label={t( 'common:name' )}
								onChange={( e ) => {
									formik.setFieldValue( 'name', e.target.value );
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								multiline
								fullWidth
								name='description'
								label={t( 'common:description' )}
								minRows={3}
								maxRows={10}
								error={gateway && descriptionLength > 255}
								helperText={gateway && descriptionLength > 255
									? t( 'common:item-description-limit' )
									: !isEmpty( formik.values.description )
										? `${descriptionLength} characters`
										: undefined}
								InputProps={{
									endAdornment: descriptionLength > 650 && (
										<InputAdornment
											position='end'
											sx={{
												position    : 'absolute',
												top         : 15,
												right       : 15,
												alignSelf   : 'start',
												p           : 1.5,
												borderRadius: 1,
												bgcolor     : 'error.main',
											}}>
											<Typography>{descriptionLength}/1500</Typography>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 1500 }}
							/>
						</Grid>
						{( formik.values.item || formik.values.name ) && (
							<Grid item xs={12} sm={6}>
								<CategorySelect
									fullWidth
									type='ITEM'
									sx={{ mb: 1 }}
									name='category'
									onAdd={undefined}
								/>
							</Grid>
						)}
						{( formik.values.item || formik.values.name ) && (
							<Grid item xs={12} sm={3}>
								<FormTextField
									fullWidth
									name='code'
									label={t( 'management:product-code' )}
									onFocus={( e ) => e.target.select()}
								/>
							</Grid>
						)}
						{( formik.values.item || formik.values.name ) && (
							<Grid item xs={12} sm={3}>
								<FormTextField
									fullWidth
									name='sku'
									label={t( 'management:sku' )}
									onFocus={( e ) => e.target.select()}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</PageSection>
		</Fragment>
	);
}
