import CustomCheckBox from '@/components/customCheckBox';
import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import { cashDiscountAtom } from '@/pages/dashboard/commerce/form/lineItemForm/index';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import useUserInfo from '@/providers/auth/useUserInfo';
import { LineItem, Price } from '@/types/schema';
import { Collapse, FormControlLabel, Grid, InputAdornment, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { useAtom } from 'jotai/index';
import { isEmpty, round } from 'lodash-es';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import PriceSelect from '../../../../formSelects/priceSelect';

export default function MarginMarkup( { isPurchase }: { isPurchase?: boolean } ) {
	const formik = useFormikContext<LineItem>();
	const { staff } = useUserInfo();
	const [ cashDiscount ] = useAtom( cashDiscountAtom );
	const uom = formik.values.uom;
	const { t } = useTranslation();
	const viewItemCosts = checkViewAllPermission( 'itemCosts' );
	
	const [ showMarkup, setShowMarkup ] = useState( Boolean( formik.values.markup && ( uom?.cost || 0 ) > 0 && ( uom?.cost === formik.values.cost || ( formik.values.cost || 0 ) > 0 ) ) );
	useUpdateEffect( () => {
		if ( !uom ) return;
		if ( Boolean( formik.values.markup && ( uom?.cost || 0 ) > 0 && ( uom.cost === formik.values.cost || ( formik.values.cost || 0 ) > 0 || formik.values.markup > 0 ) ) ) {
			setShowMarkup( true );
		}
	}, [ uom ] );
	const nonRemovedUoms = formik.values.item?.uoms?.filter( ( uom ) => uom.selected || !uom.removed );
	const price = isPurchase ? nonRemovedUoms?.[ 0 ]?.cost : nonRemovedUoms?.[ 0 ]?.price;
	
	if ( isPurchase ) return null;
	
	return (
		<Fragment>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<FormControlLabel
						checked={showMarkup ?? false}
						label={t( 'common:markup' )}
						control={<CustomCheckBox/>}
						sx={{ px: 1, pt: 1 }}
						onChange={( e, checked ) => {
							if ( checked ) {
								setShowMarkup( true );
								formik.setFieldValue( 'cost', formik.values.cost || uom?.cost || 0 );
								
							} else {
								formik.setFieldValue( 'markup', 0 );
								formik.setFieldValue( 'price', round( price ? price + price * cashDiscount : 0, 2 ) );
								setShowMarkup( false );
							}
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={8}>
					{formik.values.item && nonRemovedUoms?.[ 1 ] && (
						<FormSelect
							fullWidth
							name='uom.id'
							label={t( 'common:variants' )}
							onChange={( e ) => {
								formik.setFieldValue( 'uom', nonRemovedUoms.find( ( { id } ) => id === e.target.value ) );
							}}>
							{nonRemovedUoms.map( ( uom ) => (
								<MenuItem key={uom.id} value={uom.id}>
									{uom.name} ({uom?.sku || uom?.code || uom?.price}) - {uom?.quantity || 0} in stock
								</MenuItem>
							) )}
						</FormSelect>
					)}
				</Grid>
			</Grid>
			<Collapse unmountOnExit in={showMarkup && viewItemCosts} sx={{ width: '100%' }}>
				<Grid container spacing={1} mt={1}>
					<Grid item xs={12} sm={9}>
						<PriceSelect
							multiple
							fullWidth
							name='item.markupPrices'
							textFieldProps={{ label: t( 'management:mark-up-prices' ) }}
							value={formik.values.item?.markupPrices || []}
							variables={{
								options: {
									filter: {
										company : staff?.company?.id,
										isMarkup: true,
									},
								},
							}}
							renderOption={( props, option ) => (
								<MenuItem {...props} key={option.id ?? ''}>
									{option.name} {option.value}%
								</MenuItem>
							)}
							getOptionLabel={( option: Price ) => `${option.name} ${option.value}%`}
							onChange={( e, markupPrices: Price[] ) => {
								formik.setFieldValue( 'item.markupPrices', markupPrices );
								if ( !isEmpty( formik.values.uom ) ) {
									const markupTotal = markupPrices.reduce( ( total, price ) => total + price.value, 0 );
									
									formik.setFieldValue( 'markup', markupTotal );
									formik.setFieldValue( 'uom', {
										...formik.values.uom,
										markup: markupTotal,
									} );
									
									if ( markupTotal > 0 && ( formik.values?.cost || 0 ) > 0 ) {
										formik.setFieldValue( 'price', ( formik.values?.cost || 0 ) * +markupTotal / 100 + ( formik.values?.cost || 0 ) );
									} else {
										formik.setFieldValue( 'price', nonRemovedUoms?.[ 0 ]?.price || 0 );
									}
									
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormTextField
							fullWidth
							name='markup'
							InputProps={{
								startAdornment: <InputAdornment position='start'>%</InputAdornment>,
								inputProps    : { min: 0 },
							}}
							label={t( 'common:markup' )}
							placeholder={t( 'common:margin' )}
							onFocus={( e ) => e.target.select()}
							onChange={( e ) => {
								formik.setFieldValue( 'markup', +e.target.value );
								if ( +e.target.value > 0 && ( formik.values?.cost || 0 ) > 0 ) {
									formik.setFieldValue( 'price', ( formik.values?.cost || 0 ) * +e.target.value / 100 + ( formik.values?.cost || 0 ) );
								} else {
									formik.setFieldValue( 'price', nonRemovedUoms?.[ 0 ]?.price || 0 );
								}
							}}
						/>
					</Grid>
				</Grid>
			</Collapse>
		</Fragment>
	);
}
