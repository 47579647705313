import Chips from '@/components/chips';
import CustomCheckBox from '@/components/customCheckBox';
import FormFormControlLabel from '@/components/form/fields/controlLabel';
import FormTextField from '@/components/form/fields/textField';
import TextFieldInputLabel from '@/components/form/inputLabel';
import LargeChip from '@/components/largeChip';
import currencyFormat from '@/helpers/currencyFormat';
import useAccountType from '@/helpers/useAccountType';
import { Client, LineItem, Order, Price, PriceBase } from '@/types/schema';
import { Collapse, FormControl, Grid, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { differenceWith, isEmpty, partition } from 'lodash-es';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export default function LineItemTaxes( { client, order, itemsCloverTaxes, isFetching }: {
	client?: Client,
	order?: Order,
	itemsCloverTaxes?: Price[],
	isFetching?: boolean
} ) {
	const formik = useFormikContext<LineItem>();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const [ externalTaxes, prices ] = partition( formik.values.prices, ( price ) => price.metadata?.externalTax );
	const { t } = useTranslation();
	const taxes = externalTaxes?.filter( ( tax ) => tax.name !== 'Tax (auto)' );
	
	const setLineItemCloverTaxes = ( formik, tax: PriceBase ) => {
		const modifiedTaxes = taxes?.map( ( p ) => {
			if ( tax.id === p.id ) {
				return {
					...p,
					metadata: { ...p.metadata, useTax: !p.metadata?.useTax },
				};
			}
			return p;
		} );
		formik.setFieldValue( 'prices', [
			...!isEmpty( prices ) ? prices : [],
			...!isEmpty( modifiedTaxes ) ? modifiedTaxes : [] ] );
		
		if ( !modifiedTaxes?.find( ( tax ) => tax.metadata?.useTax ) ) {
			formik.setFieldValue( 'tax', 0 );
		}
	};
	
	useEffect( () => {
		if ( !isEmpty( itemsCloverTaxes ) && !isFetching ) {
			const cloverTaxes = differenceWith( itemsCloverTaxes, taxes, ( a: Price,
				b: PriceBase ) => a?.externalId === b?.metadata?.externalId );
			formik.setFieldValue( 'prices',
				[ ...formik.values.prices,
				  ...cloverTaxes.map( ( tax ) => ( {
					  ...tax,
					  externalId: null,
					  metadata  : {
						  ...tax.metadata,
						  externalId: tax.externalId,
						  useTax    : false,
					  },
					  id: uuidv4(),
				  } ) ) ] );
		}
		
	}, [ isFetching ] );
	
	// if ( isPurchase ) return null;
	
	if ( client?.metadata?.exemptFromTax ) {
		return (
			<LargeChip
				label={t( 'common:client-tax-exempt' )}
				color='warning'
				sx={{ mt: 1 }}
			/>
		);
	}
	
	return (
		<Fragment>
			<FormFormControlLabel
				name='orderTax'
				label={`${t( 'common:order-tax-label' )} ${order?.taxPercent ? `(${order.taxPercent}%)` : ''}`}
				control={<CustomCheckBox/>}
				sx={{ px: 1, pt: 1 }}
				onChange={( e, checked ) => {
					formik.setFieldValue( 'orderTax', checked );
					formik.setFieldValue( 'tax', checked ? null : 0 );
					formik.setFieldValue( 'prices',
						[ ...!isEmpty( prices ) ? prices : [],
						  ...!isEmpty( taxes ) ? taxes.map( ( tax ) => ( {
							  ...tax,
							  metadata: { ...tax.metadata, useTax: false },
						  } ) ) : [] ] );
				}}
			/>
			{/* @ts-ignore */}
			<Collapse in={!formik.values.orderTax}>
				<Grid item container spacing={1} my={1}>
					{!isEmpty( taxes ) && (
						<Grid item xs={12} sm={9}>
							{isCloverAccount && (
								<TextFieldInputLabel
									label={t( 'common:line-item-tax-label' )}
									tooltip={t( 'common:line-item-tax-tooltip' )}
								/>
							)}
							<FormControl fullWidth>
								<Select
									fullWidth
									multiple
									placeholder={t( 'common:taxes' )}
									sx={{ '.MuiSelect-select': { p: '7px' } }}
									value={taxes?.filter( ( tax ) => tax.metadata?.useTax )}
									renderValue={( selected ) => (
										<Chips>
											{selected?.map( ( tax ) => `${tax.name} ${tax.isPercent
												? `${tax.value}%`
												: currencyFormat( tax.value )}` )}
										</Chips>
									)}>
									{taxes?.map( ( tax, index ) => (
										<MenuItem
											key={index}
											selected
											value={tax.id}
											onClick={() => setLineItemCloverTaxes( formik, tax )}>
											<CustomCheckBox checked={Boolean( taxes.find( ( pr ) => pr.id === tax.id && pr.metadata?.useTax ) )}/>
											<ListItemText
												primary={`${tax.name} ${tax.isPercent
													? `${tax.value}%`
													: currencyFormat( tax.value )}`}
											/>
										</MenuItem>
									) )}
								</Select>
							</FormControl>
						</Grid>
					)}
					<Grid item xs={12} sm={3}>
						<FormTextField
							fullWidth
							name='tax'
							type='number'
							label={t( 'common:custom-tax-label' )}
							tooltip={`${t( 'common:custom-tax-tooltip-only-item' )} ${isCloverAccount
								? t( 'common:custom-tax-tooltip-clover-tax' )
								: ''}`}
							InputProps={{
								inputProps    : { min: 0 },
								startAdornment: <Typography pr={1}>%</Typography>,
							}}
							format={( value ) => Math.abs( +value ) || 0}
							onFocus={( e ) => e.target.select()}
							onChange={( e ) => {
								formik.setFieldValue( 'tax', +e.target.value );
								if ( +e.target.value < 1 ) {
									return;
								}
								if ( taxes?.find( ( pr ) => pr.metadata?.useTax ) ) {
									formik.setFieldValue( 'prices',
										[ ...!isEmpty( prices ) ? prices : [],
										  ...!isEmpty( taxes ) ? taxes.map( ( p ) => ( {
											  ...p,
											  metadata: { ...p.metadata, useTax: false },
										  } ) ) : [] ],
									);
								}
								
							}}
						/>
					</Grid>
				</Grid>
			</Collapse>
		</Fragment>
	);
}
