import InputStepper from '@/components/form/fields/InputStepper';
import FormTextField from '@/components/form/fields/textField';
import PageSection from '@/components/page/section';
import { Client } from '@/generated/graphql';
import { cashDiscountAtom } from '@/pages/dashboard/commerce/form/lineItemForm/index';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import { Discount as DiscountIcon, Savings as SavingsIcon } from '@mui/icons-material';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useAtom } from 'jotai/index';
import { round } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import type { LineItem } from '../../../../../types/schema';

export const setPriceAndOriginalPrice = ( formik: any, price: number, cashDiscount: number ) => {
	const cdValue = cashDiscount ? round( price ? price * cashDiscount : 0, 2 ) : 0;
	const cdPrice = round( price + cdValue, 2 );
	formik.setFieldValue( 'price', cdPrice );
	if ( cdValue ) {
		formik.setFieldValue( 'originalPrice', cdValue ? round( cdPrice ? cdPrice - cdValue : 0, 2 ) : null );
		formik.setFieldValue( 'cashDiscount', cdValue || null );
	}
};

export default function LineItemFormUom( { isPurchase, client }: { isPurchase?: boolean, client?: Client } ) {
	const formik = useFormikContext<LineItem>();
	const [ cashDiscount ] = useAtom( cashDiscountAtom );
	const { t } = useTranslation();
	const uom = formik.values.uom;
	const viewItemCosts = checkViewAllPermission( 'itemCosts' );
	const clientCategoryWithDiscount = client?.categories?.filter( ( category ) => category.type === 'CLIENT' && ( category.clientDiscount || 0 ) > 0 )
		.sort( ( a, b ) => ( b.clientDiscount || 0 ) - ( a.clientDiscount || 0 ) );
	
	const clientDiscount = clientCategoryWithDiscount?.[ 0 ]?.clientDiscount || 0;
	
	useUpdateEffect( () => {
		if ( !uom ) return;
		setPriceAndOriginalPrice( formik, uom.price, cashDiscount );
		formik.setFieldValue( 'unit', uom.name );
		formik.setFieldValue( 'quantity', 1 );
		formik.setFieldValue( 'code', uom.code );
		formik.setFieldValue( 'sku', uom.sku );
		formik.setFieldValue( 'cost', uom.cost );
	}, [ uom?.name, uom?.price, uom?.code, uom?.cost ] );
	
	return (
		<PageSection
			primary=''
			primaryTypographyProps={{ variant: 'h5' }}
			secondary={isPurchase && t( 'common:cost-hidden' )}>
			<Grid container spacing={1}>
				{!isPurchase && (
					<Grid item xs={6} sm={3}>
						{viewItemCosts && (
							<FormTextField
								fullWidth
								includeNegative
								tooltip={!isPurchase
									? 'The cost is for internal purposes only and will not be shared with your customer or displayed publicly anywhere.'
									: ''}
								name='cost'
								InputProps={{
									sx            : { color: 'text.secondary' },
									startAdornment: (
										<Typography color='text.secondary' pr={.5}>$</Typography>
									),
								}}
								label={t( 'common:cost' )}
								type='number'
								format={( value ) => +value || 0}
								onFocus={( e ) => e.target.select()}
							/>
						)}
					</Grid>
				)}
				<Grid item xs={6} sm={2}>
					<FormTextField
						fullWidth
						name='unit'
						label={t( 'common:uom' )}
						onFocus={( e ) => e.target.select()}
					/>
				</Grid>
				<Grid item xs={12} sm={3}>
					<FormTextField
						fullWidth
						includeNegative
						name='price'
						tooltip={isPurchase
							? 'The price is for internal purposes only and will not be shared with your vendor or displayed publicly anywhere.'
							: ''}
						InputProps={{
							sx            : { color: isPurchase ? 'text.secondary' : 'text.primary' },
							startAdornment: (
								<Typography color='text.secondary' pr={.5}>$</Typography>
							),
						}}
						label={!isPurchase ? (
							<Stack direction='row' sx={{ textAlign: 'right' }}>
								<Typography sx={{ fontWeight: 500 }} pr={1}>{t( 'common:price' )}</Typography>
								{cashDiscount > 0 && <Tooltip
									title={t( 'commerce:item-price-includes-fee', { discount: round( cashDiscount * 100, 2 ) } )}>
									<SavingsIcon sx={{ fontSize: 18, color: 'success.main' }}/>
								</Tooltip>}
								{clientDiscount > 0 && <Tooltip
									title={`Client discount applied ${clientDiscount}%`}>
									<DiscountIcon sx={{ fontSize: 18, color: 'warning.main' }}/>
								</Tooltip>}
							</Stack>
						) : t( 'common:price' )}
						type='number'
						format={( value ) => +value || 0}
						onFocus={( e ) => e.target.select()}
						onChange={async ( e ) => {
							const value = +e.target.value;
							await formik.setFieldValue( 'price', value );
							if ( formik.values.item ) {
								const cdValue = cashDiscount ? round( value ? value * cashDiscount : 0, 2 ) : 0;
								await formik.setFieldValue( 'originalPrice', cdValue ? round( value
									? value - cdValue
									: 0, 2 ) : null );
								await formik.setFieldValue( 'cashDiscount', cdValue || null );
							}
						}}
					/>
				</Grid>
				{isPurchase && viewItemCosts && (
					<Grid item xs={12} sm={3}>
						<FormTextField
							fullWidth
							includeNegative
							name='cost'
							InputProps={{
								startAdornment: (
									<Typography color='text.secondary' pr={.5}>$</Typography>
								),
							}}
							label={t( 'common:cost' )}
							type='number'
							format={( value ) => +value || 0}
							onFocus={( e ) => e.target.select()}
						/>
					</Grid>
				)}
				<Grid item xs={12} sm={4} sx={{ '.MuiPaper-root': { m: 'auto' } }}>
					<InputStepper
						label={t( 'common:quantity-full' )}
						name='quantity'
						maxVal={formik.values.item?.removeUom ? formik.values.uom?.quantity || undefined : undefined}
					/>
				</Grid>
			</Grid>
			{formik.values?.price < 0 && !isPurchase && (
				<Typography p={1} color='error'>
					{t( 'common:no-negative-value' )}
				</Typography>
			)}
		</PageSection>
	);
}
