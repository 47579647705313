import CustomCheckBox from '@/components/customCheckBox';
import EnhancedTable from '@/components/enhancedDisplay/enhancedTable';
import { DollarPercentageSwitch } from '@/components/form/asyncLoading/switch/mui-switch';
import PageSection from '@/components/page/section';
import { Box, FormControlLabel, FormHelperText, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEmpty, partition } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LineItem, Price, PriceBase } from '../../../../../types/schema';

export default function LineItemFees( { isPurchase }: { isPurchase?: boolean } ) {
	const formik = useFormikContext<LineItem>();
	const prices = formik.values?.prices;
	const [ taxes, discounts ] = partition( prices, ( price ) => price?.metadata?.externalTax );
	const [ data, setData ] = useState( discounts || [] );
	const { t } = useTranslation();
	
	const hasDiscounts = !isEmpty( prices?.filter( ( price ) => !price.metadata?.priceId && !price?.metadata?.externalTax ) );
	useEffect( () => {
		formik.setFieldValue( 'prices', [
			...!isEmpty( taxes ) ? taxes : [],
			...!isEmpty( prices )
				? prices?.filter( ( price ) => !price.metadata?.priceId && !price?.metadata?.externalTax )
				: [] ] );
	}, [] );
	
	useEffect( () => {
		if ( !isEmpty( discounts ) ) {
			setData( discounts );
		}
		
	}, [ formik.values?.prices ] );
	
	if ( isPurchase ) return null;
	
	return (
		<PageSection
			primary={t( 'common:discounts' )}
			primaryTypographyProps={{ variant: 'h5' }}>
			<Box py={1}>
				<FormControlLabel
					label={t( 'common:enable-discounts' )}
					checked={hasDiscounts}
					control={<CustomCheckBox/>}
					sx={{ px: 1 }}
					onChange={( e, checked ) => {
						const newValue = {
							id       : nanoid(),
							name     : 'Discount',
							value    : -10,
							isPercent: true,
							metadata : null,
						};
						setData( [ newValue as PriceBase ] );
						formik.setFieldValue( 'prices', [
							...!isEmpty( taxes ) ? taxes : [],
							checked
								? newValue as PriceBase
								: undefined ].filter( Boolean ) );
					}}
				/>
				{hasDiscounts && (
					<FormHelperText>
						{t( 'common:remove-discounts' )}
					</FormHelperText>
				)}
			</Box>
			{hasDiscounts && (
				<EnhancedTable<Price>
					data={data || discounts}
					extraData={1}
					setData={( discounts ) => {
						setData( discounts );
						formik.setFieldValue( 'prices', [ ...!isEmpty( taxes ) ? taxes : [], ...discounts ] );
					}}
					pageSectionProps={{ hide: true, boxProps: { sx: { mt: 1 } } }}
					editable={{
						onAdd: () => {
							const newValue = {
								id       : nanoid(),
								name     : 'Discount',
								value    : -10,
								isPercent: true,
								metadata : null,
							};
							setData( [ ...!isEmpty( data ) ? data : [], newValue as PriceBase ] );
							formik.setFieldValue( 'prices', [ ...!isEmpty( taxes ) ? taxes : [],
								...!isEmpty( data ) ? data : [], newValue as PriceBase ] );
						},
						onDelete: ( item ) => {
							const remainingData = data.filter( ( row ) => row.id !== item.id );
							setData( remainingData );
							formik.setFieldValue( 'prices', [
								...!isEmpty( taxes ) ? taxes : [],
								...remainingData ] );
							
						},
					}}
					headers={[ t( 'common:name' ), t( 'common:type' ), t( 'common:price' ) ]}
					columns={( item, index ) => [
						<TextField
							key='name'
							fullWidth
							value={item.name}
							error={item.name.length > 64}
							helperText={item.name.length > 64 ? 'Name should not exceed more than 64 characters.' : null}
							onChange={( e ) => {
								const newData = data.map( ( discount ) => {
									if ( discount.id === item.id ) {
										return { ...discount, name: e.target.value };
									} else {
										return discount;
									}
								} );
								setData( newData );
								formik.setFieldValue( 'prices', [
									...!isEmpty( taxes ) ? taxes : [],
									...newData,
								] );
							}}
						/>,
						<DollarPercentageSwitch
							key='switch'
							checked={item.isPercent}
							sx={{ textAlign: 'center' }}
							onChange={( e, checked ) => {
								const newData = data.map( ( discount ) => {
									if ( discount.id === item.id ) {
										return { ...discount, isPercent: checked };
									} else {
										return discount;
									}
								} );
								setData( newData );
								formik.setFieldValue( 'prices', [
									...!isEmpty( taxes ) ? taxes : [],
									...newData,
								] );
							}}
						/>,
						<TextField
							key='Value'
							fullWidth
							value={item.value}
							type='number'
							onFocus={( e ) => e.target.select()}
							onChange={( e ) => {
								const value = -+e.target.value;
								const newData = data.map( ( discount ) => {
									if ( discount.id === item.id ) {
										return { ...discount, value: value > 0 ? -value : value };
									} else {
										return discount;
									}
								} );
								setData( newData );
								formik.setFieldValue( 'prices', [
									...!isEmpty( taxes ) ? taxes : [],
									...newData,
								] );
							}}
						/>,
					]}
					tableContainerProps={{
						sx: {
							border      : 1,
							borderRadius: 2,
							borderColor : 'divider',
							bgcolor     : 'background.default',
						},
					}}
				/>
			)}
		</PageSection>
	);
}
