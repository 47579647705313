import { ListItemComp } from '@/components/listItem';
import Tags from '@/pages/dashboard/commerce/form/lineItemForm/tags';
import useUserInfo from '@/providers/auth/useUserInfo';
import { LineItem } from '@/types/schema';
import { useFormikContext } from 'formik';
import React from 'react';

export default function LineItemStatus() {
	const formik = useFormikContext<LineItem>();
	const { staff } = useUserInfo();
	
	return (
		<ListItemComp
			primary='Line Item Tag'
			primaryTypographyProps={{ fontWeight: 'bold' }}
			secondary='Tag status for this item'
			listItemProps={{ disableGutters: true }}
			rightComp={(
				<Tags
					lineItem={formik.values}
					company={staff?.company}
					setTag={( tag ) => formik.setFieldValue( 'status', tag )}
				/>
			)}
		/>
	);
}

